<template>
  <v-dialog
    v-model="dialog"
    :disabled="!$auth.check({ allergens: 'edit' })"
    width="auto"
  >
    <template #activator="{ on }">
      <v-list-item
        selectable
        v-on="on"
      >
        <v-list-item-content>
          {{ allergen.name }}
        </v-list-item-content>
        <v-list-item-action-text>
          <v-chip
            v-if="allergen.group_id"
            outlined
            small
            v-text="allergen.group.name"
          />
          <v-chip
            v-else
            small
            color="brown lighten-1"
            dark
          >
            <v-icon
              small
              class="ml-n1 mr-2"
            >
              fa-circle-star fa-beat
            </v-icon>
            Click to categorize
          </v-chip>
        </v-list-item-action-text>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>
        {{ allergen.name }}
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="allergen.group_id"
          :loading="groupsLoading"
          :items="groups"
          label="Allergen Group"
          item-text="name"
          item-value="id"
          menu-props="offsetY"
          prepend-icon="fal fa-allergies"
          no-data-text="No matching groups found"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="dialog = false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="groupsLoading"
          @click="updateAllergen"
        >
          {{ $t('Update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    allergen: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    groupsLoading: false,
    groups: [],
  }),
  watch: {
    dialog () {
      if (!this.groups.length) {
        this.fetchGroups()
      }
    },
  },
  methods: {
    fetchGroups () {
      this.groupsLoading = true
      const promise = this.axios.get('admin/allergens/groups')
      return promise.then((response) => {
        this.groups = response.data
        this.groupsLoading = false
      }).catch(error => {
        this.$toast.error(error)
      })
    },
    addGroup () {
      this.newGroupLoading = true
      this.axios.post('admin/allergens/groups', { name: this.newGroupName })
        .then((res) => {
          this.fetchGroups(this.newGroupName)
          this.organism.category = res.data
          this.newGroupDialog = false
        })
        .catch(error => {
          this.$toast.error(error)
        })
        .finally(() => {
          this.newGroupLoading = false
        })
    },
    updateAllergen () {
      this.loading = true
      this.axios.patch('admin/allergens/' + this.allergen.id, this.allergen)
        .then((res) => {
          this.allergen = res.data
          this.$emit('updated', this.allergen)
          this.dialog = false
        })
        .catch(error => {
          this.$toast.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
